div.mapLocationSelector {
	clear: both;
	height: 400px;
	margin-bottom: 20px;
}

img.mapLocationCenterPin {
	z-index: 999;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-100%);
	height: 40px;
	pointer-events: none;
	opacity: 0.8;
}
