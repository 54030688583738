div.tagProposer {
	font-size: 15px;
	color: rgba(0, 0, 0, 0.7);

	display: none;
}
div.tagProposer.visible {
	display: block;
}

div.tagProposer-header {
	margin-bottom: 5px;
	font-weight: bold;
}
div.tagProposer-footer {
	margin-top: -5px;
	margin-bottom: 10px;
}

div.tagProposer .textButton {
	padding: 8px 12px 4px 12px;
	background: #1BCBAC;
	color: white;
	text-transform: uppercase;
	line-height: 16px;
	border-radius: 16px;
}

div.tagProposer .textButton:hover {
	text-decoration: none;
}

div.tagProposer .tagProposerList {
	margin-top: 8px;
	margin-bottom: 2px;
	display: inline;
	user-select: none;
}

div.tagProposer div.tagProposerButton {
	height: 28px;
	margin-right: 12px;
	margin-bottom: 12px;
	display: inline-block;
	padding: 0 8px 0 4px;
	border: 1px solid #888;
	border-radius: 16px;
	cursor: pointer;
	background: white;
	filter: saturate(0%);
	opacity: 0.6;
}

div.tagProposer div.tagProposerButton.selected {
	color: black;
	filter: none;
	opacity: 1;
	border-color: #b5b5b5;
}

div.tagProposer img.tagProposerButtonImage {
	height: calc(100% + 2px);
	position: relative;
	top: -1px;
	left: -5px;
	vertical-align: middle;
}

div.tagProposer span.tagProposerButtonText {
	position: relative;
	/*top: -8px;*/
	margin-right: 2px;
	margin-left: 2px;
	padding: 0;
}

.tagProposerAddAll {
	/*margin-top: 10px;*/
	user-select: none;
}

.tagProposerDialog-explanation {
	padding: 20px 20px 20px 20px;
}

.tagProposerDialog-tagProposer {
	padding: 10px 20px;
}

.tagProposerDialog-offerCard {
	padding: 20px 20px 20px 20px;
	border: 1px solid #d2d2d2;
	background: #f2f2f2;
	margin-bottom: 10px;
}

.tagProposerDialog-offerName {
	font-weight: bold;
	margin-bottom: 10px;
}

.tagProposerDialog-offerDescription {
	font-style: italic;
}
