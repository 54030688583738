.tableDiv {
	width: 100%;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	min-height: 100%;
	z-index: 1;

	/* margin-bottom: 20px; */
}

/*
.tableDiv::before {
	content: "";
	display: block;
	background-color: #b8b8b7;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}
*/
.table {
	/* min-height: calc(100vh - 152px); */
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 7px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.row {
	text-align: center;
	border-bottom: 1px solid #f0f0f0;
	background-color: white;
	white-space: nowrap;
	width: 100%;
	justify-content: flex-start;
	padding-left: calc(50% - 500px)
}

.orders .row-container:not(:last-child) .row {
	border-bottom: 1px solid #808080;
}

.row > * {
}

.row.hidden {
	display: none !important;
}

.row a {
	color: #373737;
}

.row.old, .row.old a {
	color: #888;
}

.row a:hover {
	color: #5a5a5a;
}

.table .row:nth-child(even) {
	/*	background-color: #f9f9f9; */
}

.row:last-child {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.row.inactive {
	color: lightgray;
}

.row .buttonContainer {
	order: 10;
	-webkit-order: 10;
	display: flex;
}

.narrow .row .button {
	min-height: 38px;
	padding: 10px 10px;
}

.tableDiv.narrow .row .button {
	min-height: 32px;
	max-height: 32px;
}

.tableDiv.narrow .row .button .iconButton {
	padding-top: 0;
}

.headerRow {
	/* background-color: #5a5a5a; */
	color: #6f6a73;
	border: 0;
	position: relative;
	min-height: 50px;
	text-align: center;
	text-transform: uppercase;
	font-size: 13px;
	line-height: 1;

}

.headerRow:after {
	background: #f0f0f0;
	display: block;
	content: "";
	position: absolute;
	bottom: 0;
	height: 1px;
	left: 9px;
	right: 9px;
	/* opacity: 0.4; */
}

.cell {
	display: inline-flex;
	flex-direction: row;
	text-align: left;
	padding: 5px;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1;
}

/* trigger normal behavior; other cells force nowrap */
.cell-wrap {
	white-space: normal;
	line-height: initial;
}

.cell-small-text {
	font-size: 14px;
}

.tableDiv .cell.cell-align-right {
	text-align: right;
	justify-content: flex-end;
}

.tableDiv.narrow .cell {
	margin: 5px 0;
}
.tableDiv:not(.narrow) .cell {
	min-height: 45px;
}

.headerCell {
	padding: 5px 5px;
	white-space: pre-line;
	line-height: 1.4;
}

.smallHeader {
	display: none;
}

.tableDiv.narrow .smallHeader {
	display: block;
	text-align: center;
	padding: 5px;
	font-size: 16px;
	background-color: #5a5a5a;
	color: #c1c0be;
}

.tableDiv.narrow .row {
	display: block;
	position: relative;
	white-space: normal;
	padding-top: 7px;
	padding-left: 5px;
	padding-bottom: 10px;
	text-align: right;
	overflow: auto;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tableDiv.narrow .buttonContainer {
	float: right;
	margin-bottom: -10px;
	margin-top: -10px;
}

.tableDiv.narrow .cell {

	padding-top: 2px;
	padding-bottom: 3px;
}

.tableDiv.narrow .cell.primary {

	max-width: 100% !important;
	display: block;
	font-size: 18px;
	font-weight: bold;
	color: #383339;
	padding-bottom: 2px;
	padding-top: 2px;
	text-align: left;
}

.tableDiv .cell:nth-of-type(1) {
	padding-left: 10px;
}

.tableDiv.narrow .cell:nth-of-type(1) {
	padding-left: 5px;
}

.tableDiv.narrow .cell.secondary,
.tableDiv.narrow .cell.primary.showName {
	min-width: 0 !important;
	max-width: 100% !important;
	display: inline-block;
	float: left;
	font-size: 15px;
	color: #737074; /* #383339 @ 0.7 opacity*/
}

.tableDiv.narrow .cell.button {
	min-width: 0 !important;
	max-width: 100% !important;
	display: inline-block;
	color: #737074; /* #383339 @ 0.7 opacity*/
	overflow: visible !important;
}

.tableDiv .row.inactive .cell.primary, .tableDiv .row.inactive .cell.secondary, .tableDiv .row.inactive .cell.button {
	color: #9c999c;
}

.tableDiv.narrow .cell.button .iconButton {
	position: relative;
}

.tableDiv.narrow .cell.button:empty {
	display: none;
}

.tableDiv.narrow .cell .quantity {
	font-weight: normal;
}

.tableDiv.narrow .cell[data-name="Id"] {
	position: absolute;
	top: 0;
	right: 0;
}

.tableDiv.narrow .cell[data-name="Id"]:before {
	display: none;
}

.tableDiv.narrow .textButton {
	border: 1px solid;
	padding: 4px;
	border-radius: 4px;
}

.tableDiv.narrow .cell.showName:before {
	content: attr(data-name) ' ';
	font-weight: bold;
}

.tableDiv.narrow .headerRow {
	display: none !important;
	border-bottom: #ccc;
}

.table .basicButton {
	padding-top: 5px;
	padding-bottom: 5px;
	margin-top: -8px;
	margin-bottom: -8px;
}

.tableDiv.narrow .basicButton {
	background-color: transparent;
	margin-top: -9px;
}

.tableDiv.narrow .basicConfirmButton {
	background-color: #1bcbac;
}

.table .row a {
	text-decoration: none;
	font-weight: normal;
	font-size: smaller;
}

.narrow .table .row a {
	font-size: inherit;
}

.tableDiv:not(.narrow) .buttonContainer img.iconButton {
	/* margin-top: 5px; */
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

.row {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	-webkit-flex-direction: column;
	-webkit-box-orient: vertical;
	display: flex;
	flex-direction: row;
}

.offerTable > .table > .row-container > .row.disabled {
	color: #ccc;
}

.offerTable > .table > .row-container > .row img.disabled {
	opacity: 0.3;
}
