body {
	background-color: #fafafa;
	margin: 0;
	/* padding-bottom: 20px; */
	overflow: auto;
	height: auto;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	/* align-items: center; */

	padding-top: 60px;
}

@media screen and (min-width: 1051px) {
	body {
		/*
		Ok, this looks like a hack. Maybe it is. But it's important. (pun intended)

		Our TopBar navigation updates document.body.style.paddingTop based on the height of the TopBar.
		Height is 120px only if screen is small and we are in a primary tab page.
		TopBar doesn't want to care about screen width. That's why we force 60px padding top when screen is wide.
		*/
		padding-top: 60px !important;
	}
}

.modalDialog {
	/*min-width: 650px;*/
	border-radius: 0;
}

#infoText {
	margin: 20px;
	text-align: left;
	color: white;

    max-width: 1015px;
	margin: auto;
	margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

#infoText .pageTitleText {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
}

#infoText.warningInfoText {
	outline: 10px solid #FFF4AD;
	background-color: #FFF4AD;
}

#infoText.warningInfoText b {
	font-weight: bold;
}

#infoText.warningInfoText .textButton {
	font-weight: bold;
	color: #000;
	text-decoration: underline;
}

#content {
	position: relative;
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: auto;
	/* max-width: 1000px; */
	margin-right: auto;
	width: 100%;
	z-index: 0;
	padding-bottom: 20px
}

#bottom {
	/* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5); */
	position: static;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #f0f0f0;
	font-size: 13px;
	/* height: 30px; */
	/* padding: 7px 10px; */
	/* line-height: 3px; */
	color: #d5d5d5;
	background-color: transparent;
	/* background: linear-gradient(transparent, black 435%); */
	background: #f0f0f0;
	display: none;
}
@media screen and (max-height: 360px){
	.provider #bottom {
		display: none;
	}
}
@media screen and (max-width: 1100px) {
	.tabButton {
		font-weight: normal;
		font-size: 15px;
		padding-left: 10px;
		letter-spacing: -0.4px;
		padding-right: 10px;
	}
}

@media screen and (max-width: 960px) {
	.tabButton {
		font-size: 12px;
		padding-left: 4px;
		padding-right: 4px;
	}
	.provider .tabContainer {
		left: auto !important;
	}
}

.placeholderText {
	/* this makes it roughly in the center or slighly above it; there's really no real
	 * effort to properly place it vertically */
	padding-top: 25vh;
	text-align: center;
}

#infoTextHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

#infoTextHeader > .pageTitleText {
  margin-bottom: 0;
  padding-right: 10px;
}

.viewSwitch {
  max-height: 50px;
  padding: 5px;
  border: 1px solid #4fa08b;
  border-radius: 4px;
  background-color: #4fa08b;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
}

.viewSwitch:hover {
  background-color: #64d4c0;
  border-color: #64d4c0;
}