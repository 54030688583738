body {
	background-color: white;
	padding-top: 50px;
}

/* Solves FastClick bug */
label > * { pointer-events: none; }

body.noTopBar {
	padding-top: 0;
}

html, body {
	min-height: 100%;
	min-width: 280px;
	margin: 0;
	box-sizing: border-box;
	font-weight: 300;
	color: #373737;
	position: relative;
}

*, :after, :before {
	 box-sizing: border-box;
}

body, input, textarea, button, select, option {
	/* TODO remove Brother1816 when the new brother-1816 is everywhere with the correct weights */
	font-family: 'Brother1816', 'brother-1816', 'Helvetica', 'Arial', sans-serif;
	/* TODO flipping brothers will cause things to look slightly different due to different fonts - FIX */
	/*font-family: 'brother-1816', 'Brother1816', 'Helvetica', 'Arial', sans-serif;*/
	font-size: 17px;
	/*color: #373737;*/
	font-weight: 300;
}

h2, h3, .comic, .step-number {
	font-weight: 700;
}

h3 {
	/* letter-spacing: 0.03em; */
	font-weight: normal;
	font-size: 18px;
}

.white {
	color: white;
}
.green {
	color: #1BCBAC;
}
.black {
	color: black;
}
.gray {
	color: #6F6A73;
}
.yellow {
	color: #DCFF3D;
}
.selectNone {
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
}

.showWhenLoggedIn {
	display: none;
}

b, strong {
	font-weight: bold;
}

body a {
	/*font-weight: bold;*/
	color: #1BCBAC;
}

body a:hover, body a:focus {
	color: #1BCBAC;
}

a:hover {
	/*border-bottom: 1px solid;*/
}

h2 {
	margin-bottom: 20px;
}
h3 {
	font-size: 18px;
}
p {
	margin-bottom: 14px;
}

.hidden {
	display: none;
}

.tabButton {
	color: white;
	display: inline-block;
	padding: 12px 15px;
	position: relative;
	top: 2px;
	opacity: 1;
	color: #fff;
	font-size: 16px;
	-webkit-transition: color 0.5s; /* For Safari 3.1 to 6.0 */
	transition: color 0.5s;
	line-height: 22px;
	text-overflow: ellipsis;
	overflow: hidden;

}

.tabButton:hover, .tabButton.selected {
	opacity: 1;
	color: white;
	border-bottom-color: #bbb;
}
.tabButton.selected {
	font-weight: 500;
	font-weight: bold;
}
.tabButton:hover {
	color: white;
}
.tabContainer {
	display: inline-block;
	/*margin-left: 40px;*/
	position: relative;
	height: 100%;
	white-space: nowrap;
}

.imageWithTextButtonContainer {
	text-align: center;
	color: #5a5a5a;
	width: 200px;
	margin: 0px auto;
	font-weight: 500;
}

.imageWithTextButtonContainer:hover {
	color: #373737;
	opacity: 0.7;
}

.imageWithTextButtonContainer img {
	display: inline-block;
	width: 50px;
	height: 50px;
	margin-bottom: 5px;
}

.iconButton {

	box-sizing: content-box;
	width: 1.2em;
	height: 1.2em;
	cursor: pointer;
	opacity: 0.75;
	padding: 8px 10px;
	position: relative;
	top: 0px;
	left: 0px;
}

.iconButton:hover {
	opacity: 1;
}

.textButton {
	display: inline-block;
}

.textButton:hover {
	text-decoration: underline;
}


.textButton, .imageWithTextButtonContainer, .tabButton, .basicButton {
	cursor: pointer;
}

.imageWithTextButtonContainer, .tabButton, .basicButton {
	-webkit-user-select: none; /* Chrome/Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */

	/* Rules below not implemented in browsers yet */
	-o-user-select: none;
	user-select: none;
}

.basicButton {
	color: #1bcbac;
	border: 2px solid #1bcbac;
	background: transparent;
	font-weight: 500;
	border-radius: 4px;
	padding: 7px 13px;
	min-height: 44px;
}

.basicButton:hover, .basicButton:focus {
	outline-style: none;
}

.basicButton:disabled, select.basicInput:disabled {
	color: #777;
	opacity: 0.6;
}

.inputWrapper {
	position: relative;
}

.basicButton:hover:not(:disabled), .basicButton:focus {
	border-color: #1bcbac;
}

.basicButton:active:not(:disabled) {
	color: rgba(68, 68, 68, 1.0);
	border: 1px solid rgba(68, 68, 68, 0.5);
	background: #eee;
	background-image: linear-gradient(to bottom, #ededed, #efefef);
}

.basicButton.basicDeleteButton {
	background: #F65555;
	background-image: linear-gradient(to bottom, #F65555, #F54747);
	border-color: #F65555;
	color: #f3f3f3;
}

.basicDeleteButton:hover:not(:disabled), .basicDeleteButton:focus {
	border-color: rgba(255, 255, 255, 0.5);
}

.basicDeleteButton:active:not(:disabled) {
	background-image: linear-gradient(to bottom, #F54747, #F65555);
	border-color: rgba(255, 255, 255, 1);
	color: white;
}

.basicButton.basicCancelButton {
	background: hsl(0, 0%, 39%);
	background-image: linear-gradient(to bottom, hsl(0, 0%, 39%), hsl(0, 0%, 35%));
	border-color: hsl(0, 0%, 35%);
	color: white;
}

.basicCancelButton:hover:not(:disabled), .basicCancelButton:focus {
	border-color: rgba(255, 255, 255, 0.5);
}

.basicCancelButton:active:not(:disabled) {
	background-image: linear-gradient(to bottom, hsl(0, 0%, 35%), hsl(0, 0%, 39%));
	border-color: rgba(255, 255, 255, 1);
	color: white;
}

.basicButton.basicConfirmButton {
	background: #1BCBAC;
	/*background: hsl(87, 57%, 56%);*/
	/*background-image: linear-gradient(to bottom, hsla(87,57%,60%,1), hsla(87,44%,55%,1));*/
	/*border-color: hsla(87, 44%, 55%, 1);*/
	border-color: transparent;
	color: white;
}

.basicConfirmButton:hover:not(:disabled), .basicConfirmButton:focus {
	/* background-image: linear-gradient(to bottom, hsla(87,60%,60%,1), hsla(87,50%,55%,1)); */
	background: #49e9cb;
	/*border-color: rgba(255, 255, 255, 0.5);*/
	border-color: transparent;
}

.basicConfirmButton:active:not(:disabled) {
	/* background-image: linear-gradient(to bottom, hsla(87,47%,56%,1), hsla(87,54%,59%,1)); */
	background: #16a78c;
	border-color: transparent;
	color: white;
}

.basicButton.modalDialogGrayButton {
	background: #eee;
	border-color: #eee;
}

.fancyButton {
	padding: 20px 40px;
	border-radius: 20px;
	border: 1px solid #1BCBAC;
	color: #1BCBAC;
	font-size: 18px;
	background: transparent;
	font-weight: 500;

	transition: 0.5s all;
}

.fancyButton:hover, .fancyButton:focus, .fancyButton:active {
	background: #1BCBAC !important;
	color: white !important;
	border-color: #1BCBAC !important;
}


.inputWrapper-select {
	color: #5a5a5a;
	margin-right: 8px;
	width: 100%;
}

.inputWrapper-select::after {
	content: '▾';
	display: block;
	position: absolute;
	right: 3px;
	top: 5px;
	width: 18px;

	line-height: 38px;

	pointer-events: none;
}

/* Figure out why this must be done and undo it */
.myInfoSettings-myInfo .inputWrapper-select::after {
	right: 0;
}


html.ie .inputWrapper-select::after {
	display: none;
}

.inputWrapper-select > select.basicInput {
	background: white;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline-style: none;
	width: 100%;
	padding-right: 24px;
}

.inputWrapper-select > select.basicInput:hover:not(:disabled),
.inputWrapper-select > select.basicInput:focus {
	border-color: #888;
}


.navigationButton {
	width: 100%;
	position: relative;
	padding: 1.2em 0;
	cursor: pointer;

	font-size: 14px;
	font-weight: 500;

	border-bottom: 0.5px solid #f0f0f0;
}
.navigationButton .navigationButtonArrowIcon {
	position: absolute;
	right: 0px;
	top: 1em;
	height: 1.5em;
}
.navigationButton:hover {
	color: #777;
}
.navigationButton:active {
	color: #1BCBAC;
}
.navigationButtonText {
	margin-left: 4em;
}
.navigationButtonIcon {
	position: absolute;
	left: 0px;
    top: 0.6em;
    height: 2.4em;
}


/* for videos */
.playButton {
	width: 8vw;
	height: 4.5vw;
	font-size: 2vw;
	padding: 0 0 0 .4vw;
}

.topBar {
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 50px;
	height: 50px;
	z-index: 11000;
	background: #1BCBAC !important;
}

.topBar .logo {
	display: inline-block;
	position: relative;
	height: 60% !important;
	top: 20%;
}

.topBar .logoLeft {
	position: absolute;
	left: 20px;
	height: 50% !important;
	top: 25%;
}

.topBar .logo img {
	height: 100% !important;
}

.topBar .topBarBurgerButton {
	display: none;
}

.topBar .languageSelector-touchArea:last-child {
	right: 2px;
	color: white
}

.languageSelector-touchArea {
	position: absolute;
	/* position: absolute;
	right: 0;
	height: 40px; */
	top: 0;
	padding-left: 10px;
	cursor: pointer;
	font-size: 14px;
	/* color: white; */
}

.top-menu .languageSelector-touchArea {
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
}

.open .languageSelector {
	display: block;
}
.languageSelector {
	display: none;
	color: rgba(0,0,0,0.7);
	font-weight: bold;
	user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	overflow: hidden;
	transition: .1s height, 0.5s border-color;
	z-index: 10000;
	background: #F2F2F2;
	position: absolute;
	top: 40px;
	right: 0;
	padding: 15px;
	cursor: default;
}

.languageSelector.open {
	height: auto;
	background: #F2F2F2;
}
.languageSelector .language {
	height: 35px;
	line-height: 35px;
	padding: 0 15px;
	cursor: pointer;
}
.languageSelector .language + .language {
	border-top: 1px solid #979797;
}
.languageSelector-touchArea .current-lang {
	line-height: 55px;
	margin-right: 20px;
	position: relative;
}

/*
.languageSelector-touchArea .current-lang:after {
	content:'\0052';
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	font-family: 'resq';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	bottom: -1px;
}
*/
/*
html.touch .topBar .languageSelector.open {
	height: calc(41px * 2 - 1px);
}

html.touch .topBar .languageSelector.open > div {
	line-height: 39px;
}

.topBar .languageSelector.open > div:hover {
	color: white;
	transition: color .1s;
}
*/
.topBar .languageSelector > div + div {
	/* opacity: 0; */
}
/*
.topBar .languageSelector > div + div::before {
	content: ' ';
	display: block;
	height: 1px;
	width: 80%;
	margin: 0 auto;
	background: #ccc;
	opacity: 0;
	transition: opacity .8s;
}

.topBar .languageSelector.open > div + div::before {
	opacity: 1;
}

.topBar .languageSelector.open > div + div {
	opacity: 1;
}

*/

.topBar .topBarLogoutButton.topBarElement {
	position: absolute;
	display: inline-block;
	top: 12px;
	right: 64px;
	color: #ffffff;
	text-align: right;
	padding: 5px 10px;
	font-size: 13px;
}

.topBar .topBarBurgerButton {
	height: 100%;
	position: absolute;
	top: 0;
	right: 5px;
	display: inline-block;
	cursor: pointer;
	padding: 0 15px;
}
.topBar .topBarBurgerButton img {
	position: relative;
	top: 20%;
	height: 60%;
}

.topBar .topBarMenu {
	position: absolute;
	right: 0;
	top: 50px;
	background-color: #16b599;
	padding-right: 0;
	display: none;
	left: 0;
	padding-bottom: 20px;
}
.topBar .topBarMenu .tabButton {
	display: block;
	text-align: left;
	padding-left: 20px;
	border-left: 4px solid transparent;
	padding-top: 9px;
	padding-bottom: 9px;
	line-height: 42px;
	font-size: 18px;
	position: relative;
}
.topBar .topBarMenu > * + *:not(.languageSelector-touchArea)::after{
	content: "";
	position: absolute;
	top: 0;
	left: 20px;
	right: 0;
	height: 1px;
	background: rgba(0,0,0,0.05);
}
.topBar .topBarMenu .tabButton.selected {
	border-left-color: #DCFF3D;
}


.modalLayer {
	opacity: 0.7;
	background-color: white;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 10000;
}


input.basicInput, textarea.basicInput, select.basicInput {
	border-style: none;
	padding: 9px;
	border-radius: 4px;
	border: 1px solid #c6c6c6;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 12px;
	outline: none !important;

	-webkit-transition:border 0.3s;
	transition:border 0.3s;
}

select.basicInput {
	margin-bottom: 10px;
}

.basicInput:not(:disabled):not([readonly]):hover, .basicInput:not(:disabled):not([readonly]):focus {
	outline:none;
	/* border:1px solid #6f6a73; */
}

textarea.basicInput {
	/*
	resize: none;
	height: 6em;
	*/
}

.basicInput[readonly], .basicInput[disabled] {
	border: 1px solid #ddd;
	background-color: #f0f0f0;
	opacity: 0.5;
	box-shadow: inset 0 0 3px rgba(120, 120, 120, 0.1);
}

.basicInput::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color: #aaa;
}

.basicInput:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: #aaa;
	opacity: 1;
}

.basicInput::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: #aaa;
	opacity: 1;
}

.basicInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #aaa;
}

.basicInput[readonly] {
	background-color: #e6e5dd;
}

::selection {
	background-color: #64d4c0; /* chrome puts opacity or something to this color */
	opacity: 1;
	color: #fff;
	text-shadow: none;
}

::moz-selection {
	background: #64d4c0;
}

.inputBlock-type-time {
	width: auto !important;
	margin-right: 5px;
}
.inputBlock-type-time .inputBlockTitle {
	text-align: center;
}

.inputBlock {
	overflow: visible;
	position: relative;
}
.inputBlock.no-margin {
	padding-top: 0;
}

.inputBlockTitle {
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: bold;
	min-height: 23px; /* empty title should fill the space */
	text-align: left;
	overflow: visible;
}
.inputBlock-xsmall{
	display: inline-block !important;
	width: auto !important;
}
@media screen and (max-width: 576px){
	body div.inputBlock-xsmall.inputBlock{
		display: inline-block !important;
		width: auto !important;
	}
}

.inputBlock-full {
	display: block;
	width: 100%;
}
.inputBlock-half {
	width: calc(50% - 5px);
}
.inputBlock-auto {
	width: auto;
}
.inputBlock-big {
	width: calc(100% - 110px);
}
.inputBlock-small {
	width: 100px;
}
.inputBlock-notFirst {
	/*margin-left: 10px;*/
}

.inputBlock-type-search {
	/* margin: 0 5px !important; */
	margin: 0;
	padding-top: 10px;
	margin-bottom: 1px;

	background: white;
	padding-left: 10px;
	padding-right: 10px;

}
.inputBlock-type-search input {
	margin-bottom: 10px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;
}

@media screen and (max-width: 576px) {
	div.inputBlock.inputBlock-type-search {
		/*
		margin: 0 5px !important;
		width: calc(100% - 10px) !important;
		*/
	}
}

.basicInput-type-checkbox {
	border: none !important;
}
.basicInput-type-checkbox > * {
	cursor: pointer;
}
.inputBlock-type-checkbox .inputBlockTitle {
	display: none;
}

.basicInput-type-checkbox label {
	margin-left: 5px;
}
.checkbox-bigEmptyLabelClickArea {
	position: absolute;
    display: block;
    top: -9px;
    left: -20px;
    width: 42px;
    height: 42px;
}

.basicInput-type-radio {
	border: none !important;
}
.radioButtonElement {
	margin: 5px 0px;
}
.radioButtonElement label {
	margin-left: 10px;
	display: inline;
}

.basicInput-type-range {
	border: none !important;
	padding: 0 !important;
}

input.basicInput-type-search {
	padding-right: 30px;
}

.inputBlock .clearSearch {
	display: inline-block;
	position: absolute;
	color: white;
	height: 16px;
	width: 16px;
	border-radius: 8px;
	background-color: #bbb;
	right: 10px;
	top: 50%;
	margin-top: -8px;
	text-align: center;
	padding-bottom: 3px;
	line-height: 20px;
	cursor: pointer;
}

/* old and new */
input.timeInput, input.basicInput-type-time {
	text-align: center;
	width: 4.6em;
	height: 4.6em;
	border-radius: 50%;
	border: 1px solid #D2D2D2;
	font-size: 14px;

	height: 30px;
}

input.timeInput:focus, input.basicInput-type-time:focus {
	border-color: #66cc99;
}

.linkOnDark {
	color: #1BCBAC;
	text-decoration: none;
}
.linkOnDark:hover {
	color: #A2D95E;
}



.textCheckbox {
	color: gray;
	display: inline-block;

	cursor: pointer;
}

.textCheckbox.selected {
	text-decoration: underline;
	color: black;
}

.page {
	min-height: calc(100% - 70px);
	/*display: none;*/
	padding: 0;
	margin: 0;
}

footer {
	padding: 30px 0;
	background-color: #383339;
	color: #f0f0f0;
	text-align: center;
	font-size: 15px;
	line-height: 22px;
}

footer .link {
	color: #ddd;
}

footer .link img {
	height: 0.8em;
	position: relative;
	top: -1px;
	margin-right: 5px;
}

footer .row {
	width: 95%;
	max-width: 1000px;
	display: inline-block;
	text-align: center;
}

.footerBox {
	text-align: center;
	margin: 20px auto;
	width: 260px;
	max-width: 100%;
}

.footerBox h3 {
	text-transform: uppercase;
	margin-top: 30px;
}

.footerBoxIcon {
	margin-bottom: 10px;
}

.copyright {
	margin-top: 30px;
	margin-bottom: 10px;
	font-size: 12px;
	color: #aaa;
}

.flexboxSupport {
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */
	display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.text-allcaps {
	text-transform: uppercase;
}
.topBar .languageSelector .text-allcaps {
	line-height: 32px;
}

.popover {
	display: none;
	/* height: 80px; */
	left: 0;
	right: 0;
	top: 0px;
	z-index: 100;
	/* transform: translateY(106px); */
	overflow: hidden;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
	opacity: 0;
	max-width: 1000px;
	margin: 0 auto;
	border-bottom: 1px solid rgba(0,0,0,0.03);
	box-shadow: inset 0 0 20px rgba(255,255,255,1);

}
.popover-inner {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	overflow: hidden;
}

.row.selected.has-content+.popover {
	display: block;
	opacity: 1;
}
.narrow .row.selected.has-content .popover {
	/* transform: translateY(106px); */
	display: block;
	opacity: 1;
}
.row {
	transition: padding 0.2s ease-in-out;
	overflow: hidden;

}
.row.has-content.selected {
	overflow: visible !important;
}
.row.has-content.selected:before, .row:before {
	content: "";
	position: absolute;
	right: 0;
	width: 6px;
	bottom: 0;
	top: 0;
	background: #DCFF3D;
	z-index: 100;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}
.row.has-content.selected:before {
	opacity: 1;
}
.narrow .popover {
	width: 100%;
	padding-bottom: 5px;
}
.popover .popover-inner > div {
	text-align: left;
	height: 20px;
	line-height: 20px;
	font-size: 13px;
	overflow: hidden;
}
.popover .popover-inner > div span:first-of-type {
	font-weight: bold;
}

.popover .popover-inner > div:last-of-type {
	line-height: 1.5;
	height: 40px;
}
.popover .popover-inner > div:last-of-type span:last-of-type {
}

body.freeze-scroll {
	overflow: hidden;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
}

body .basicInput-type-checkbox2 {
	border: none !important;
	margin-bottom: 20px;
	margin-top: 20px;
}

.basicInput-type-checkbox2 input {
	position: absolute;
	visibility: hidden;
 }

body .basicInput-type-checkbox2 label{
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
}
body .basicInput-type-checkbox2 label > span{
	display: block;
	flex: 1;
	font-weight: bold;
}
body .basicInput-type-checkbox2 label .checkbox-slider {
	width: 60px;
	height: 30px;
	border-radius: 30px;
	flex-shrink: 0;
	border: 1px solid transparent;
	background: rgba(0, 0, 0, 0.5);
}

label input:checked + .checkbox-slider {
	background: #1bcbac;
	border-color: #1bcbac;
}
label input:checked + .checkbox-list-button-background {

	/*background: #1bcbac;*/
	/*border-color: #149981;*/

}

label input:disabled + .checkbox-slider {
	opacity: 0.5;
}
label input:checked + .checkbox-slider .checkbox-slider-knob {
	transform: translateX(30px);
}
body .basicInput-type-checkbox2 label .checkbox-slider-knob {
	transition: transform 0.2s ease-in-out;
	background: #f7f7f7;
	box-shadow: inset 0 1px 2px white;
	height: 28px;
	width: 28px;
	border-radius: 100%;
}

body .checkbox-list-buttons {
	list-style-type: none;
	padding: 0;
	margin: 0;
	margin-bottom: 30px;
	transition: 0.2s all ease-in-out;
	overflow: hidden;
	border-top: 1px solid rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

body .checkbox-list-buttons > li {

}
body .checkbox-list-buttons > li > label {
	min-height: 50px;
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 10px;
}
body .checkbox-list-buttons > li > label input {
	margin-top: -5px;
	position: absolute;
	visibility: hidden;

}
body .checkbox-list-buttons > li > label input+.checkbox-list-button-background::before {
	background: white;
	border-radius: 2px;
	content: "";
	position: absolute;
	top: 50%;
	left: 20px;
	height: 16px;
	width: 16px;
	margin-top: -8px;
	border: 1px solid rgba(0,0,0,0.2)
}
body .checkbox-list-buttons > li > label:hover input+.checkbox-list-button-background::before{
	border-color: black;
}
body .checkbox-list-buttons > li > label:hover input:checked+.checkbox-list-button-background::before{
	border-color: black;
}
body .checkbox-list-buttons > li > label input:checked+.checkbox-list-button-background::before {
	border-color: #15a289;

}
body .checkbox-list-buttons > li > label input:checked+.checkbox-list-button-background::after{
	/*Add another block-level blank space*/
	content: '';
	display: block;

	/*Make it a small rectangle so the border will create an L-shape*/
	width: 5px;
	height: 10px;

	/*Add a white border on the bottom and left, creating that 'L' */
	border: solid #000;
	border-width: 0 2px 2px 0;

	/*Rotate the L 45 degrees to turn it into a checkmark*/
	transform: rotate(45deg);
	left: 26px;
	top: 50%;
	margin-top: -6px;
	position: absolute;

}
body .checkbox-list-buttons > li > label  span {
	margin-top:1px;

}
body .checkbox-list-buttons > li > label {
	color: rgba(0,0,0,0.5)
}
body .checkbox-list-buttons > li + li {
}
body .checkbox-list-buttons label input:checked  + div span {
	color: rgba(0,0,0,0.85) !important;
}
body .checkbox-list-buttons .checkbox-list-button-background {

	background: white;
	display: flex;
	align-items: center;
	position: absolute;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	padding-left: 50px;

	font-size: 15px !important;
	line-height: 50px;
	padding-top: 4px;

}
body .checkbox-list-buttons li  + li > label::after {
	content: "";
	display: block;
	position: absolute;
	left: 20px;
	right: 0;
	top: 0;
	height: 1px;
	background: rgba(0,0,0,0.1);
}

body .checkbox-list-buttons input:checked+.checkbox-list-button-background {
	color: rgba(0,0,0,0.5)
}

.inputBlock-type-radio.inputBlock-checkboxButtons {
	margin-bottom: 10px;
	margin-top: 20px;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer {
	display: flex;
	margin: 0;
	padding: 0;
	min-height: 30px;
}

.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer::after {
	content: "";
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer::before {
	content: "";
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
}

.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement {
	margin: 0;
	padding: 0;
	flex-grow: 1;
	flex-basis: 25%;
	text-align: center;
	position: relative;

}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement:nth-of-type(1)::after {
	content: "";
	display: none;
	min-width: 10px;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input{
	position: absolute;
	bottom: 0;
	right: 0;
	visibility: hidden;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement label {
	display: block;
	margin: 0;
	padding: 0;
	font-size: 14px !important;
	line-height: 30px;
	border: 1px solid rgba(0,0,0,0.1);
	position: relative;
	text-align: center;
	font-weight: normal;
	color: rgba(0,0,0,0.5);
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	color: #1bcbac;
	border-color: #1bcbac;
	padding-left: 10px;
	padding-right: 10px;
}
/*
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement label::before, .inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement label::after{
	content: "";
	display: block;
	width: 10px;
}
*/
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement:first-child label {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement:last-child label {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement label:hover {
/* 	border-color: rgba(0,0,0,0.3); */
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input:checked+label {
	color: white;
	border-color: #1bcbac;
	background: #1bcbac;
}/*
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input+label::before {
	content: "";
	position: absolute;
	top: 50%;
	margin-top: -8px;
	height: 16px;
	width: 16px;
	background: white;
	border-radius: 100%;
	left: 10px;
	border: 1px solid rgba(0,0,0,0.25);
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input:checked+label::before {
	border: 1px solid #1bcbac;
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input:hover+label::before {
	border: 1px solid rgba(0,0,0,0.8);
}
.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input:checked:hover+label::before {
	border: 1px solid #1bcbac;
}

.inputBlock-type-radio.inputBlock-checkboxButtons .radioButtonContainer .radioButtonElement input:checked+label::after {
	content: "";
	position: absolute;
	top: 50%;
	margin-top: -3px;
	height: 6px;
	width: 6px;
	background: black;
	border-radius: 100%;
	left: 15px;
}
*/
.inputBlock-collapse {
	/* width: 100%; */
	position: relative;
	transition: top 0.2s ease-in-out;
	background: #fafafa;
	overflow: hidden;
	width: calc(100% + 40px);
	margin-left: -20px;
	margin-right: -20px;
	border-top: 1px solid rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba(0,0,0,0.1);
	margin-top: -1px;
	padding-top: 1px;
	opacity: 1;
}
.inputBlock-collapse.expanded {
	height: auto !important;
	position: relative !important;
	top: 0 !important;
	transition: none !important;
	overflow: visible;
}
.inputBlock.inputBlock-collapse.expanded {

}
.modalBody .placeholder {
	display: none;
}
body .inputBlock-collapse.expanded .inputBlockTitle {
	height: 60px;
	position: relative;
}
body .modalBody.scroll-freeze {
	padding-top: 100px !important;
}
/*
@media screen and (min-device-width: 766px){
	.inputBlock.inputBlock-collapse.expanded {

	}
	.modalBody .placeholder {
		display: none;
	}
	body .inputBlock-collapse.expanded .inputBlockTitle {
		height: 60px;
	}
	body .modalBody.scroll-freeze {
		padding-top: 100px !important;
	}
}
@media screen and (max-device-width: 768px){
	.inputBlock.inputBlock-collapse.expanded .inputBlockContent {
		height: auto !important;
		top: 0 !important;
		bottom: 0;
		position: fixed;
		left: 0;
		right: 0;
		z-index: 1000000;
		overflow-y: scroll !important;
		-webkit-overflow-scrolling: touch;
		background: #fafafa;
		overflow: visible;
	}

}
*/
.inputBlock-collapse .basicInput {

}
.inputBlock-collapse.expanded .basicInput {
	transform: translate3d(0,0,0);
}

.inputBlock.inputBlock-collapse > *{
/* 	display: none !important; */
	height: 0;
}
.inputBlock.inputBlock-collapse.expanded > * {
	display: flex !important;
	height: inherit;
}
.inputBlock.inputBlock-collapse:not(.expanded) > * {

}

.modal-ui-hidden .inputBlock.inputBlock-collapse:not(.expanded) input[type=text]{

}
.provider body .inputBlock.inputBlock-collapse:not(.expanded) input {
	/*
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0;
	margin: 0;
	display: block;
	height: auto;
	opacity: 0.00001;
	*/
}

.modalDialog .inputBlock:not(.inputBlock-collapse) {
	padding-left: 20px;
	padding-right: 20px;
}

@media screen and (min-width: 700px){
	.inputBlock-collapse.expanded {
		max-width: 700px;
		/* left: calc(50% - 350px) */
	}
	.inputBlock-collapse {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.modalDialog .inputBlock-collapse.expanded .inputBlockTitle {
		max-width: 700px;
		margin: 0 auto;
	}
}

.provider body.modal-ui-hidden .modalDialog .modalBody {
	bottom: 0;
	top: 0;
}

.inputBlock.inputBlock-collapse .inputBlock-helpText {
	margin-bottom: 20px;
	font-size: 15px;
	color: rgba(0,0,0,0.7);
}

.inputBlock-collapse .spacer {
	height: 10px;
	border-top: 1px solid rgba(0,0,0,0.1);
	border-bottom: 1px solid rgba(0,0,0,0.1)
}

.inputBlock-collapse .inputBlockTitle .spacer {
	min-width: 40px;
}

:not(+.inputBlock-collapse) + .inputBlock-collapse {

}
.inputBlock-collapse +.inputBlock-collapse {
	border-top: none;
}

.inputBlock-collapse +.inputBlock-collapse::after {
	content: "";
	display: block;
	position: absolute;
	left: 20px;
	right: 0;
	top: 0;
	height: 1px;
	background: rgba(0,0,0,0.1)
}

/* forward arrow */
.inputBlock-collapse .inputBlockTitle::after {
	/*Add another block-level blank space*/
	content: '';
	display: block;

	/*Make it a small rectangle so the border will create an L-shape*/
	width: 10px;
	height: 10px;

	/*Add a white border on the bottom and left, creating that 'L' */
	border: solid #000;
	border-width: 0px 0px 2px 2px;

	/*Rotate the L 45 degrees to turn it into a checkmark*/
	transform: rotate(315deg);
	right: 10px;
	top: 50%;
	margin-top: -9px;
	position: absolute;
	opacity: 0.5;
	transition: all 0.25s ease-in-out;
}
.inputBlock-disabled {
	opacity: 0.5;
}
.inputBlock-disabled.inputBlock-collapse .inputBlockTitle::after {
	display: none;
}
.inputBlock-collapse.expanded .inputBlockTitle::after {
	/*Add another block-level blank space*/
	content: '';
	display: block;

	/*Make it a small rectangle so the border will create an L-shape*/
	width: 10px;
	height: 10px;

	/*Add a white border on the bottom and left, creating that 'L' */
	border: solid #000;
	border-width: 0px 0px 2px 2px;

	/*Rotate the L 45 degrees to turn it into a checkmark*/
	transform: rotate(135deg);
	right: 10px;
	top: 50%;
	margin-top: -6px;
	position: absolute;
	opacity: 0.5;
}
.inputBlock-collapse .inputBlockContent {
	height: 0;
	overflow: hidden;
	transition: height 0.2s ease-in-out;
	padding-left: 20px;
	padding-right: 20px;
}

.inputBlock-collapse.expanded .inputBlockContent {
	display: flex;
	flex-direction: column;
	opacity: 1;
	padding-top: 20px;
	padding-bottom: 20px;
	box-shadow: inset 0px 6px 21px rgba(0,0,0,0.1);

}
.provider body .blockHeader {
	padding-left: 20px;
	font-size: 15px;
	top: 0;
}
.inputBlock-collapse .inputBlockTitle {
	display: flex !important;
	height: 60px;
	width: 100%;
	background: white;
	font-size: 15px;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	padding-left: 20px;
	margin: 0;
	color: unset;
	text-decoration: unset;
}
@media screen and (max-width: 370px){
	 html.provider body .inputBlockContent, html.provider body .inputBlockContent label *, html.provider body .inputBlockTitle {
		font-size: 13px;
	}
}

.inputBlock-collapse .inputBlockTitle.active::before {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0,0.1);
}

.inputBlock-collapse.expanded .inputBlockTitle {
	/*
	position: absolute !important;
	position: sticky !important;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	height: 50px;
	border-bottom: 1px solid rgba(0,0,0,0.1);

	*/
	/* padding-left: 45px; */
}

/* back arrow */

.INVALID inputBlock-collapse.expanded .inputBlockTitle::before {
	/*Add another block-level blank space*/
	content: '';
	display: block;

	/*Make it a small rectangle so the border will create an L-shape*/
	width: 10px;
	height: 10px;

	/*Add a white border on the bottom and left, creating that 'L' */
	border: solid #000;
	border-width: 0px 0px 2px 2px;

	/*Rotate the L 45 degrees to turn it into a checkmark*/
	transform: rotate(45deg);
	left: 26px;
	top: 30px;
	margin-top: -6px;
	position: absolute;
}

.inputBlock-collapse .inputBlockTitle .inputBlock-label-summary {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
	text-align: right;
	font-weight: normal;
	color: rgba(0,0,0,0.7);
	padding-right: 30px;
}
.inputBlock-collapse.expanded .inputBlockTitle .inputBlock-label-summary {
	opacity: 0;
}
.inputBlock-collapse .inputBlockTitle .inputBlock-label-summary-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 20px;
}

@keyframes inputBlock-collapse-block {
	from {
		height: 100vh;
		top:0;
	}
	to {
		height: 60px;
		top: 0;
	}
}
.toolbar {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	max-width: 1015px;
	margin: 0 auto;
	width: 100%;
	padding: 10px;
}
.toolbar > * {
	flex: 1;
	max-width: 300px;
	padding: 0;
	margin: 0;
}
.provider body .toolbar  .inputBlock input{
	padding: 0;
	margin: 0 !important;
}
.toolbar > * + * {
	margin-left: 10px;
}
.toolbar .basicButton {
	height: 32px;
	min-height: 0px;
	/*border: none;*/
	font-weight: bold;
}
@media screen and (max-width: 700px){
	.toolbar * {
		font-size: 14px !important;
	}
}
.provider body .modalFooter {
	transition: transform 0.25s ease-in-out;
}

@media screen and (max-width: 700px){

	.provider body.input-focus .modalFooter {
		transform: translateY(60px);
	}
	.provider body.input-focus .modalBody {
		bottom: 0 !important;
	}

	.inputBlock.inputBlock-collapse .inputBlock-helpText {
		font-size: 13px;
	}
}


@keyframes modal-bg-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes modal-dialog-in {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes modal-bg-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes modal-dialog-out {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-100%);
	}
}
.modal-background {
	background-color: rgba(0, 0, 0, 0.7);
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	animation-name: modal-bg-in;
}
.modal-background.modal-hide{
	animation-name: modal-bg-out;
}
.modalDialog:not(.modalDialog-nonpopup){
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	animation-name: modal-dialog-in;
}
.modalDialog-container.modal-hide .modalDialog {
	animation-name: modal-dialog-out;

}
.modalDialog {
}

.provider .topBarElement .tabButton.tabButton-support {
	display: none;
}

.highlighted-warning-text {
	display: inline-block;
	margin-left: 6px;
	font-size: 13px;
	font-weight: normal;
	text-transform: uppercase;

	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	background-color: #FFF4AD;
}

.inputBlock-warning {
	background-color: #FFF4AD;
	transition: background-color 0.5s;
}

.provider .inputBlock-warning > input {
	transition: border-color 0.5s;
}

.provider .inputBlock-warning > input:focus {
	border-bottom: 2px solid #aaa;
	padding-bottom: 1px;
}

.infoText-warningTag {
	display: inline-block;
	margin-left: 6px;
	font-size: 13px;
	font-weight: normal;
	text-transform: uppercase;

	padding-top: 4px;
	padding-left: 6px;
	padding-right: 6px;
	background-color: #FFF4AD;
}
