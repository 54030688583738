
.fill {
	min-height: 100% !important;
}

.doubleContainer {
	max-width: 600px;
	margin: 20px auto;
}

.halfContainer {
	width: 50%;
	padding: 10px 30px;
}

.halfContainer:first-child {
	border-right: 1px solid #c1c0be;
}

#infoText, .halfContainer {
	color: #5a5a5a;
	font-weight: normal;
	font-size: 16px;
	/* position: fixed; */
	/* bottom: 0; */
	/* right: 0; */
	/* z-index: 100; */
	position: static;
}

#infoText:empty {
}

#infoText a {
	text-decoration: none;
}

.settings {
	max-width: 1000px;
	margin: auto;
	margin-bottom: 20px;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
}

.content-stats > .settings:not(:nth-child(1)) {
	display: none;
}

.content-stats > .tableDiv:not(:nth-child(2)) {
	display: none;
}

.content-stats .tableDiv.narrow .cell.primary:before {
	content: attr(data-name) ' ';
	position: absolute;
	display: inline-block;
	font-weight: normal !important;
	left: 0;
	text-align: left;
}

.settings .inputBlock {
	margin-bottom: 8px;
	margin-right: 5px;
}

.settings .inputBlockTitle {
	margin-bottom: 5px;
}

.settings::after {
	content: '';
	display: block;
	clear: both;
}

div.buttons {
	padding-top: 28px;
}

div.buttons > button.basicButton {
	min-height: 37px;
	margin-right: 6px;
	display: inline-block;
	margin-top: 5px;
}

#input_year, #input_month {
	margin-top: 5px;
}

.settings .basicButton {
	padding-top: 5px;
	padding-bottom: 5px;
}

.orderNotification {
	display: inline-block;
	position: absolute;
	top: 10px;
	right: 3px;
	width: 15px;
	height: 15px;
	background-color: #465C50;
	color: white;
	font-size: 10px;
	border-radius: 50%;
	text-align: center;
	font-weight: normal;
}

.orderNotification span {
	position: relative;
	top: -13px;
}

.orderInstructions {
	border: 1px solid #aaa;
	margin: 10px;
	border-radius: 20px;
	padding: 7px 25px;
	font-size: 14px;
	max-width: 820px;
	margin: 10px auto;
	margin-bottom: 20px;
	width: 90%;
	box-sizing: border-box;
}

.orderInstructionsTitle {
	font-weight: bold;
	margin: 10px 0;
}

.orderInstructionsFlex {
	overflow: auto;

	position: relative;

	flex-flow: wrap;
	justify-content: space-between;

	margin: 10px -5px;
}

.tag-selection {
	width: calc(100%);
	display: flex !important;
	flex-direction: column;
	margin: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tag-selection.tag-selection-food-type {
	width: auto;
	margin: 30px;
	margin-top: 5px;
	margin-bottom: 25px;
	padding: 2px;
	display: inline-block;
	background: #f0f0f0;
	border-radius: 7px;
	border: 1px solid #E3E3E3;
}

.tag-selection.tag-selection-food-type .tag-button {
	padding: 0 0 0 10px;
	margin: 4px;
	border-radius: 4px;
	min-width: initial;
}

.tag-selection.tag-selection-food-type .tag-button-text {
	padding: 10px 10px 10px 0;
	border-radius: 4px;
	margin: 2px;
	color: #373737;
	box-sizing: border-box;
	border: 1px solid transparent;
	display: inline-block;
}

.tag-selection.tag-selection-food-type .tag-button.selected {
	background-color: #fafafa;
	color: black;
	border: 1px solid #595959;
}

.tag-selection-subtitle {
	font-size: 12px;
	margin-top: -2px;
	margin-bottom: -9px;
	color: #1BCBAC;
	color: black;
	font-weight: bold;
	margin-left: 30px;
}

.provider .tag-button::after {
	display: none;
}

.tag-button {
	min-height: 50px;
	display: flex;
	margin: 0px;
	padding: 3px;
	padding-top: 0px;
	box-sizing: border-box;
	cursor: pointer;
	-webkit-user-select: none; /* Chrome/Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */

	/* Rules below not implemented in browsers yet */
	-o-user-select: none;
	user-select: none;
	align-items: center;
	justify-content: flex-start;
	background: white;
	/* border-bottom: 1px solid rgba(0,0,0,0.1); */
	margin-bottom: 0px;
	padding-left: 20px;
	position: relative;
	/*	border: 1px solid rgba(0,0,0,0.15); */
}

.inputBlock-collapse-tags .inputBlockContent .tag-selection {
	margin-left: -20px;
	margin-right: -20px;
	width: calc(100% + 40px)
}

.tag-button-inline {
    height: 1.5em;
    vertical-align: middle;
	/* tweak to make up for font deficiencies */
    position: relative;
    top: -2px;

	/* there's always text after this, is there? */
    margin-right: 6px;
	/* compensate for some air contained in the icon */
    margin-left: -4px;
}

/* Inside quick create offer menu (vue component) */
.CreateOfferFromTemplate .tag-button-inline {
	margin-right: 2px;
	top: -1px;
}

.tag-button + .tag-button::after {
	content: "";
	display: block !important;
	position: absolute;
	left: 20px;
	right: 0;
	top: 0;
	height: 1px;
	background: rgba(0, 0, 0, 0.1);
}

.tag-button-icon {
	height: 1.3em;
	width: 1.3em;
	vertical-align: baseline;
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 50%;
	margin-right: 20px;
}

.tag-button:not([disabled]).selected .tag-button-icon {
	background: rgba(0, 0, 0, 0.8);
	border-width: 1px;
	/*box-shadow: 0px 0px 1px black;*/
}

.tag-button[disabled] {
	opacity: 0.3;
}

.tag-button[disabled] img {
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
}

.tag-button:not(.selected) img {
	opacity: 0.9;
}

.tag-button .tag-button-text {
	text-shadow: none;
	color: gray;
	font-weight: normal;
}

.tag-button:not([disabled]).selected .tag-button-text,
.tag-button[class*='pseudo-selected-for-'] .tag-button-text {
	font-weight: bold;
	color: black;
}

.tag-button[class*='pseudo-selected-for-'] img {
	filter: none;
	-webkit-filter: none;
	/* border: 1px solid black; */
}

.tag-button[class*='pseudo-selected-for-'] {
	opacity: 1;
}

.tag-button[class*='pseudo-selected-for-'] .tag-button-text {
	opacity: 0.3;
}

.orderInstructionStep {
	display: inline-block;
	float: left;

	margin: 5px;
}

.orderInstructionStep > span {
	position: relative;
	top: 2px;
	margin-left: 5px;
}

.orderInstructionCircle {
	width: 20px;
	height: 20px;
	display: inline-block;
	border-radius: 50%;
	background-color: #1BCBAC;
	color: white;
	text-align: center;
}

.orderInstructionCircle span {
	position: relative;
	top: 2px;
	left: 1px;
}

.providerPublishAllButtonContainer {
	margin-bottom: 10px;
	text-align: center;
}

.providerPublishAllButtonContainer .basicButton {
	max-width: 90%;
	min-width: 300px;
}

@media (max-width: 700px) {
	.imageWithTextButtonContainer {
		width: auto;
	}

	.settings {
		max-width: 90%;
	}
}

/* Orders list styles */

.tableDiv.narrow .fetched {
	font-weight: normal;
	margin-right: 10px;
}

.tableDiv.narrow .quantity {
	display: inline;
}

.table.newOffers .row {
	transition: background-color 2.5s;
	background-color: green !important;
}

.table.newOffers .row:nth-child(even) {
	background-color: lightgreen !important;
}

.offerSound {
	display: inline-block;
	margin: 0 10px 10px 10px;
	max-width: 820px;
	width: 90%;
	margin: 0 auto;
}

.offerName, .customerName {
	width: 100%;
	max-width: 250px;
	margin-left: 10px;
	font-size: 20px;

	-webkit-appearance: textfield;
	font-size: medium;
	background: #fff;
}

.providerTimeRangeMark {
	font-size: 14px;
	display: inline-block;
	border-bottom: 1px solid;
	margin-right: 5px;
	position: relative;
	top: -7px;
	padding: 0 5px;
	color: #777;
}

.providerTimeGraphLimit {
	display: none;

}

.providerTimeGraphLimit > div {

	margin-left: -13px;
	font-size: 10px;
	border-radius: 10px;
	text-align: center;
	position: absolute;
	display: block;
	width: 30px;
	line-height: 12px;
	height: 12px;
	bottom: -12px;
	background: orange;

}

.providerTimeGraphLimitEnd > div {
	margin-left: -13px;
}

.providerTimeGraphOrder, .providerTimeGraphFetch {
	font-size: 12px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.48);
	/*border-radius: 20px;'*/
	padding-left: 5px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	transition: all 0.2s ease-in-out;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*
.tick {
	width: 1px;
	background: rgba(0,0,0,0.05);
	position: absolute;
	top: 0px;
	bottom: 0px;
}
.tick > span {
	font-size: 8px;
	position: absolute;
	top: 50%;
	margin-top: -5px;
	height: 10px;
	width: 16px;
	display: block;
	text-align: center;
	margin-left: -8px;
	color: #aaaaaa;
	line-height: 13px;
}
*/
.providerTimeGraphCurrentTime {
	/*background: #d1f9d1;*/
	background: orange;
	width: 2px;
	right: auto;

}

.providerTimeGraph {
	display: none !important;
	background: #f1f1f1;
	z-index: -1;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#providerIdentity {
	color: #353535;
	text-transform: uppercase;
	display: inline-block;
	line-height: 26px;
	padding-left: 5px;
	padding-right: 10px;
	height: 20px;
	position: relative;
	display: block;
	/* text-shadow: 0 1px 2px white; */
	font-weight: bold;
	/* top: 10px; */
}

.icon {
	width: 15px;
	height: 15px;
	display: inline-block;
	background-size: contain;
	margin: 2px 2px -1px;
}

.icon-info {
	background-image: url(../img/icon_info.png);
}

.providerFetchOrderTimesDL {
	display: flex;
	flex-direction: column;
}

.providerFetchOrderTimesDL .providerFetchOrderTimesHeaderRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.offerDetailsIcon {
	height: 15px;
	width: 15px;
	margin-bottom: -2px;
}

#admin input, #admin textarea,
.provider input, .provider textarea {
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	border-bottom: 1px solid #d2d2d2;
	padding: 0 0 1px;
	transition: all 0.2s;
	transition-property: padding, border;
}

#admin input,
.provider input {
	height: 30px;
	line-height: 38px;
}

#admin input[type=checkbox],
.provider input[type=checkbox] {
	height: initial;
}

#admin input.basicInput,
.provider input.basicInput {
	margin-bottom: 15px;
}

#admin textarea.basicInput,
.provider textarea.basicInput {
	height: 23px;
	margin-top: 7px;
	border: 1px solid #d2d2d2;
	overflow: hidden;
	margin-bottom: 20px;
	line-height: 22px;
	padding: 5px;
}

.provider textarea:focus, #admin textarea:focus {
	border-color: #1bcbac;
}

#admin input:focus,
.provider input:focus {
	border-bottom: 2px solid #1bcbac;
	padding-bottom: 1px;
}

#admin.inputBlockTitle, #admin label
.provider .inputBlockTitle, .provider label {
	font-size: 16px;
}

.modalDialog .modal-horizontalLine {
	margin-left: -40px;
	margin-bottom: 25px;
	margin-right: -40px;
	margin-top: 25px;
}

.modalDialog-nonpopup .modal-horizontalLine {
	margin-left: -74px;
	margin-bottom: 30px;
	margin-right: -73px;
	margin-top: 30px;
}

.provider .modalDialog-nonpopup {
	padding-top: 0;
}

input[type=time]::-webkit-clear-button,
input[type=time]::-webkit-outer-spin-button,
input[type=time]::-webkit-inner-spin-button {
	display: none;
}

.modalDialog:not(.modalDialog-nonpopup) {
	overflow: hidden !important;
}

.providerRangeTable {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.ProviderDLRangeRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-bottom: 20px;
	margin-left: 20px;
	margin-right: 20px;
}

.providerTimeGraph {
}

.providerDLRangeTable {
	max-width: 450px;
}

.ProviderDLTimeInput {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 38px;
}

.ProviderDLTimeInput + .ProviderDLTimeInput {
	margin-left: 10px;
}

.ProviderDLDayRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	position: relative;
}

.ProviderDLDayRow .ProviderDLDayRowHeader {
	-webkit-box-flex: 0.7;
	-ms-flex: 0.7;
	flex: 0.7;
}

.ProviderDLDayRow .ProviderDLTimeInput {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.providerFetchOrderTimesHeaderRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.providerFetchOrderTimesHeaderRow > span {
	display: block;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-overflow: ellipsis;
	text-align: center;
}

.providerFetchOrderTimesHeaderRow > .ProviderDLDayRowSpacer {
	display: block;
	-webkit-box-flex: 0.7;
	-ms-flex: 0.7;
	flex: 0.7;
}

.ProviderDLDayRowInputs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.ProviderDLTimeInput input {
	width: 100%;
	text-align: center;
	line-height: 1 !important;
}

.ProviderDLTimeInput button {
	font-size: 20px;
	width: 28px;
	height: 28px;
	line-height: 1;
	border-radius: 100%;
	color: #1bcbac;
	margin-left: 5px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
	margin-top: 0px;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 31px;
}

.ProviderDLTimeInput button:first-child:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 28px;
	width: 15px;
}

.ProviderDLTimeInput button:last-child:after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 28px;
	width: 15px;
}

.ProviderDLTimeInput button:hover {
	border: 1px solid #1bcbac;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ProviderInputControls {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	height: 0;
	right: 0;
	z-index: 1;
}

.ProviderInputControls button {
	position: absolute;
	left: 0;
	background: transparent;
	border: 1px transparent;
}

.ProviderInputControls button + button {
	left: 100%;
	margin-left: -30px
}

.ProviderDLDayRow .ProviderDLDayRowHeader {
	font-size: 14px;
	line-height: 29px;
	overflow: hidden;
	-webkit-box-flex: 0.7;
	-ms-flex: 0.7;
	flex: 0.7;
	position: relative;
}

.ProviderPublishInfo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	margin-top: 15px;
	margin-bottom: 30px;
}

.ProviderPublishInfo > span {
	display: block;

}

.ProviderPublishInfo .timeLeft {
	color: rgba(0, 0, 0, 0.7);
}

.ProviderPublishTime {
	font-size: 30px;
	line-height: 42px;
	height: 36px;
	color: #1BCBAC;
	font-weight: normal;
}

.ProviderPublishTimeWillPublishTime {
}

.ProviderDLSwitch .basicInput-type-checkbox {
	white-space: nowrap;
}

.ProviderDLSwitch input {

}

.ProviderDLSwitch label {
	margin-left: -20px;
	padding-left: 25px;
}

#admin input,
.provider input {
	background-position: bottom -1px left 0;
	background-size: 4px 1px;
	background: transparent;
}

#admin input[readonly], #admin input[disabled],
.provider input[readonly], .provider input[disabled] {
	background: transparent;
	border-bottom: 1px solid transparent;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.38) 33%, transparent 0%);
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.38) 33%, transparent 0%);
	background-position: bottom -1px left 0;
	background-size: 4px 1px;
	background-repeat: repeat-x;
}

.warningStyle {
	font-size: 14px;
}

.validationMessage {
	padding: 10px;
	padding-top: 12px;
	padding-bottom: 8px;
	border-radius: 4px;
}

.validationMessage + .validationMessage {
	margin-top: -10px !important;
}

.validationError {
	background-color: #f8d7da;
	border: 1px solid #f5c6cb;
	color: #721c24;
}

.validationWarning {
	background-color: #fff3cd;
	border: 1px solid #ffeeba;
	color: #856404;
}

.warning {
	margin-bottom: 10px;
	padding: 5px;
	font-size: 14px;
	border-radius: 2px;
	background: #f7f7f7;
	margin-left: 20px;
	margin-right: 20px;
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}

.warning > .icon {
	margin-right: 5px;
}

.warning > a {
	text-decoration: none;
	font-weight: bold;
}

.warning > hr {
	height: 0;
	border: 0;
	border-bottom: 2px solid #eeeeee;
}

.warning .inputBlock label {
	font-size: 14px !important;
}

.provider body .warning .inputBlock {
	padding: 0 !important;
}

.ProviderDescriptionExample p {
	margin: 0;
	margin-bottom: 5px;
}

.provider .topBar .topBarTitle {
	color: white;
	height: 50px;
	line-height: 54px;
	font-weight: bold;
	position: relative;
	z-index: 103;
	display: inline-block;
}

.provider .topBar .topBarBurgerButton {
	z-index: 103;
}

.provider .topBar .topBarBurgerButton {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.5) !important;
}

.topbar {
	z-index: 1000000;
}

@media screen and (max-width: 775px) {
	.provider .topBar .topBarTitle {
		text-align: left;
		padding-left: 20px;
	}
}

@media screen and (min-width: 774px) {

	.provider .tabContainer {
		position: absolute;
		top: 50px;
		left: calc(50% - 500px);
		right: auto;
		background: #16b599;
		color: white;
		height: 40px;
		line-height: 40px;
		display: flex !important;
		flex-direction: row;
		justify-content: space-around;
		max-width: 1000px;
		/* align-self: center; */
		margin: 0 auto;
		width: 100%;
		align-items: center;
	}

	.provider .topBar:after {
		content: "";
		display: block;
		background: #16b599;
		height: 40px;
		left: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	.provider .tabContainer .tabButton {
		flex: 1;
		top: 0;
		padding: 0;
		margin: 0;
		height: 40px;
		line-height: 43px;
	}

	.provider .tabContainer .tabButton.selected {
		background: #fafafa;
		font-weight: normal;
		/* border-top-left-radius: 2px; */
		color: #969696;
		/* border-top-right-radius: 2px; */
		/* top: 1px; */
	}
}

.provider .doubleContainer {
	position: absolute;
	left: -1000px;
}

@media screen and (max-width: 775px) {
	.provider .doubleContainer .halfContainer .imageWithTextButtonContainer {
		position: fixed;
		bottom: 5px;
		right: 5px;
		display: block;
		z-index: 1000;
		/* border: 1px solid red; */
		height: 100px;
		width: 100px;
		color: white;
		display: none;
	}

	.provider .doubleContainer .halfContainer .imageWithTextButtonContainer .imageWithTextButtonText {
		display: none;
	}

	.provider .tableDiv .smallHeader {
		display: none;
	}

	.provider h2.green.comic {
		/* display: none; */
		/* margin-top: -26px; */
		font-size: 20px;
		text-align: left;
	}

}

@media screen and (max-width: 360px) {
	#admin .providerDLRangeTable .ProviderDLTimeInput,
	.provider .providerDLRangeTable .ProviderDLTimeInput {
		margin-top: 22px;
	}

	#admin .providerDLRangeTable .ProviderDLDayRowHeader,
	.provider .providerDLRangeTable .ProviderDLDayRowHeader {
		position: absolute;
		top: 0;
		left: 0;
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none;
		right: 0;
		height: 20px;
		line-height: 20px;

	}

	#admin .providerDLRangeTable .providerFetchOrderTimesHeaderRow > .ProviderDLDayRowSpacer,
	.provider .providerDLRangeTable .providerFetchOrderTimesHeaderRow > .ProviderDLDayRowSpacer {
		display: none;
	}
}

.provider .basicListButton {
	border: 0;
	font-weight: bold;
	width: 100%;
}

.provider .buttonContainer {
}

.provider .buttonContainer span.basicListButton {
	font-weight: normal;
}

.provider .buttonContainer .basicListButton.danger {
	color: #a60000;
}

.provider .small-controls .basicListButton {
	font-size: 14px;
	padding: 0;
	margin: 0 2px;

	max-width: 49%;
	min-width: 49%;
	word-break: break-word;

	text-align: center;

	/*border: 1px dashed red;*/
}

.provider .small-controls {
	display: block;
	text-align: center;
	width: 100%;
	padding-right: 13px;
}

.provider .small-controls > * {
	display: inline-block;
	line-height: 1.2em;
}


.provider .small-controls .spacer {
	width: calc(1.2em + 20px);
	height: calc(1.2em + 16px);
}

.provider .cell.button {
	height: 100%;
}

.provider .small-controls {
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
}

.provider .tableDiv.narrow .small-controls {
	top: initial;
	position: initial;
	transform: initial;
}

.provider .small-controls * {
	/*border: 1px dashed blue*/
}

.provider .small-controls .basicListButton {
	outline: none;
}

.provider .small-controls .basicListButton:hover {
	text-decoration: underline;
}

.provider .small-controls .basicListButton.danger {
	font-weight: normal;
}

.provider .small-controls .basicListButton[disabled] {
	font-weight: normal;
	/* override the hover style */
	text-decoration: none;
	margin-top: 2px !important;
	cursor: default;
}

.provider .small-controls .undoButton {
	/*margin-top: 2px;*/
	color: #9a9a9a;
	/*outline: none;*/
}

.provider .small-controls .undoButton:hover {
	color: #1BCBAC;
}

.provider .small-controls button {
	flex-shrink: 1;
}

.provider .search-wrapper {
	max-width: 1015px;
	margin: 0 auto;
	width: 100%;
	/* margin-right: auto; */
}
.provider .search-wrapper .inputBlock {
	display: inline-block;
	margin-top: 30px;
}
@media screen and (max-width: 576px) {
	.provider .search-wrapper .inputBlock {
		margin-top: 10px;
	}
}
.provider .search-wrapper .ordersSortByInputBlock {
	float: right;
	margin-top: 10px;
}
.provider .search-wrapper .ordersSortByInputBlock .inputBlockTitle {
	padding-left: 10px;
}

.provider .inputBlock-type-search {
	background: transparent;
	padding-right: 10px;
}

.provider .inputBlock-type-search input {
	background: transparent;
}

.provider #content > .helpText {
	margin: 20px;
	font-size: 14px;
}

@media screen and (min-width: 720px) {
	.provider .inputBlock-type-search input {
		/*
		display: inline-block;
		width: auto;
		*/
	}

	.provider #content > .helpText {
		text-align: center;
	}
}

.provider * {
	-webkit-tap-highlight-color: transparent;
}

.provider .topBarMenu,
#admin .topBarMenu {
	transform: translateY(-1200px);
	transition: transform 0.2s ease-in-out;
	z-index: 100;
	top: 50px;
	/* this will be hidden & animated with transform trickery  */
	display: block !important;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	max-height: calc(100vh - 50px);
}

.provider .topBarMenu.visible, #admin .topBarMenu.visible {
	transform: translateY(0);
}

.provider .topBar .providerIdentity, #admin .topBar .providerIdentity {
	position: absolute;
	display: inline-block;
	right: 73px;
	top: 10px;
	text-transform: uppercase;
	font-size: 12px;
	color: white;
	z-index: 104;
}

.topBar ::selection {
	background: #13957d;
}

.provider .topBar .textButton.topBarElement.topBarLogoutButton {
	top: 21px;
}

@media screen and (max-width: 774px) {
	.provider .topBar .providerIdentity {
		top: 19px;
	}
}

@media screen and (max-width: 550px) {
	.provider .topBar .providerIdentity {
		display: none;
	}
}

.provider .topBarMenu.visible .providerIdentity {
	display: none;
}

.provider .topBar.menu-open::after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	right: 0;
	height: 50px;
	z-index: 102;
	background: #1BCBAC;
}

.content-stats .tableDiv.narrow .cell.primary {
	text-align: right;
}

.content-stats .tableDiv.narrow .row {
	padding: 20px;
}

.toastContainer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: block;
	height: 50px;
	line-height: 50px;
	background: #DCFF3D;
	color: black;
	font-size: 16px;
	z-index: 11000;
	padding-left: 10px;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	transform: translateY(-50px);
}

.toastContainer.visible {
	opacity: 1;
	transform: translateY(0);
}

.toastContainer .modalCloseButton {
	position: absolute;
	right: 0;
	top: 0;
	padding: 16px;
	height: 50px;
	width: 50px;
}

.toastContainer .modalCloseButton img {
	position: absolute;
}

.toastContainer .content {
	margin-right: 60px;
}

@media screen and (max-width: 720px) {

	.toastContainer {
		height: 67px;
		line-height: 1.5;
		padding-top: 10px;
		bottom: 0;
		top: auto;
		transform: translateY(67px);
	}

	.toastContainer.visible {
		transform: translateY(0);
	}

}

/* firefox thinks price fields are invalid */
input.basicInput-type-price:invalid {
	border: none;
	box-shadow: none;
}

@keyframes content-in {
	from {
		opacity: 0.00001;
	}
	to {
		opacity: 1;
	}
}

.provider #content.content-in {
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	animation-name: content-in;
}

.tableDiv > .loadingIndicator {
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	opacity: 1;
	transition: opacity 1s ease-in-out;
}

.provider .tableDiv .table {
	opacity: 0;
	display: none;
	transition: opacity 0.3s ease-in-out;
}

.provider .tableDiv.data-ready .table {
	display: block;
	opacity: 1;
	animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	animation-name: content-in;
}

.tableDiv.data-ready > .loadingIndicator {
	opacity: 0;
	display: none;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.spinner {
	text-align: center;
	padding-top: 75px;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #000;
	opacity: 0.8;
	margin: 5px;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.provider .modalDialog-container {
	width: 100vw;
	display: flex !important;
	justify-content: center;
	max-height: 100vh;
	overflow: hidden;
	position: absolute;
	height: 100%;
}

.provider .modalDialog {
	position: relative !important;
	padding-bottom: 0 !important;
	padding-top: 0 !important;
	max-height: 100vh;
	display: block;
	/* flex-direction: column; */
	padding: 0 !important;

}

.provider .modalDialog:not(.modalDialog-nonpopup) .modalBody {
	overflow-y: scroll;
	/* -webkit-overflow-scrolling: touch; */
	/* flex: 1; */
	position: absolute;
	top: 50px;
	bottom: 50px;
	left: 0;
	right: 0;
	z-index: 100000;
}

.provider .modalDialog:not(.modalDialog-nonpopup) .modalFooter {
	position: absolute;
	padding: 0;
	margin: 0;
	display: flex;
	bottom: 0;
	left: 0;
	right: 0;

}

.provider .modalDialog .modalFooter .modal-button {
	border-radius: 0;
	margin: 0;

	flex: 1;
	height: 50px;
}

.provider .modalDialog:not(.modalDialog-nonpopup) .modal-title {
	margin: 0 !important;
	padding-left: 20px;
	position: absolute;
	margin: 0;
	display: flex;
	background: #fafafa;
	top: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.provider .modalDialog .modal-horizontalLine {
	margin: 1px 0 14px 0;
	height: 10px;
	border-bottom: 1px solid black;
	clear: both;
}
.provider .modalDialog .modal-horizontalLine.no-margin-light {
	margin: 0;
	padding: 0;
	height: 0;
	border-bottom: 1px solid #e4e4e4;
}

.provider .flex-center {
	display: flex;
	justify-content: center;
}

.provider .modalDialog-nonpopup {
	max-height: unset;
}

.provider .modalDialog-nonpopup .modalBody {
	max-height: unset;
	overflow-y: visible !important;
}

.tabButton, .basicListButton, .provider .cell.button img, .provider a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0.3) !important;
}

.provider .scroll-freeze {
	max-height: 100vh !important;
	overflow-y: hidden;

}

.provider body.scroll-freeze {
	height: 100%;
}

.provider body.scroll-freeze .body-wrapper {
	height: 100vh;
	overflow: hidden;
}

/*
.provider .scroll-freeze .modalBody.modalPopupSection {
}
*/
.provider textarea {
	background: transparent;
}

.provider .basicListButton.secondary {
	color: gray;
}

.provider *[class|=icon-repeat] {
	position: absolute;
	left: 0;
	top: 1px;
}

.provider .icon-repeat-on {
	width: 15px;
	height: 14px;
	display: block;
	margin-right: 5px;
	background: url(/provider/img/icon_repeat_on.svg) no-repeat;
}

.provider .icon-repeat-off {
	width: 15px;
	height: 14px;
	display: block;
	margin-right: 5px;
	background: url(/provider/img/icon_repeat_off.svg) no-repeat;
}

.provider .table-header {
	height: 40px;
	font-size: 17px;
	text-transform: uppercase;
	line-height: 44px;
	text-align: center;
	color: #343035;
	position: static;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: linear-gradient(#fafafa, #f3f3f3);
	border-bottom: 1px solid #e6e6e6;
	justify-content: space-between;
}

.provider .header-container {
	/* display: flex; */
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 1015px;
	margin: 0 auto;
	width: 100%;
	/* flex-direction: row; */

	margin-top: -97px;
}

.provider .header-container .helpText {
	flex: 3;
	visibility: hidden;
}

.provider .header-container .action-container {
	/* display: flex; */
	/* align-items: center; */
	/* justify-content: center; */
	/* flex: 1; */
	/* margin-right: 20px; */

	float: right
}

.provider .header-container .action-container a {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
}

.provider a:hover, .provider .primaryButton:hover {
	color: #169b82;
}

.provider .header-container .action-container a:active div, .provider .header-container .action-container a:hover div {
	background: linear-gradient(#179b82, #169b82);
}

.provider .header-container .action-container a div {
	height: 45px;
	width: 45px;
	border-radius: 100%;
	position: relative;
	flex: 1;
	border: 1px solid rgba(0, 0, 0, 0.05);
	background: linear-gradient(#1bd6b7, #1bcbac);
}

.provider .header-container .action-container a div::after {
	content: "+";
	color: white;
	font-size: 30px;
	line-height: 50px;
	text-align: center;
	display: block;
}

.provider .header-container .action-container a span {
	font-size: 14px;
	margin-top: 10px;
	text-transform: uppercase;
	text-align: center;
	flex-shrink: 0;

}

@media screen and (max-device-width: 550px) {
	.provider .header-container {
		flex-direction: column;
		padding: 10px;
		font-size: 14px;
	}

	.provider .header-container .helpText {
		flex: none;
		visibility: hidden;
		height: 20px;
	}

	.provider .header-container .action-container {
		margin-top: 20px;
		justify-content: flex-start;
	}

	.provider .header-container .action-container a {
		/* flex-direction: row; */
	}

	.provider .header-container .action-container a span {
		/* margin: 0; */
		margin-left: 10px;
	}
}

.provider .table-header .spacer {
	flex: 1;
}

.provider .table-header .button-placeholder {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}

.provider .table-header .button-placeholder button {
	flex-shrink: 1;
	min-height: unset;
	margin: 2px;
}

@media screen and (max-width: 775px) {
	.provider .table-header {
		top: 50px;
	}

	.provider .modalBody {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

@media screen and (max-width: 700px) and ( min-width: 575px) {
	.provider body .modalDialog .modalBody {

		padding-left: 20px !important;
		padding-right: 20px !important;

	}
}

.provider .modalDialog .modalBody {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.provider .modalDialog:not(.modalDialog-nonpopup) .modalBody {

	padding-top: 0px;
}

.provider .tableDiv.narrow {
	/* border-top: 1px #EBEBEB solid; */
}

.provider .tableDiv.narrow .row {
	/* background-color: #f9f9f9; */
	background-color: white;

	overflow: hidden;
}

.provider .table {
	background: #FAFAFA;
	/* padding-bottom: 40px; */
}

.provider .tableDiv .row:last-child {
	box-shadow: none;
}

.provider .modalBody .description {
	margin: 0 20px 10px;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.5);
}

.provider .content-stats * {
	user-select: text;
}

.provider .content-stats .tableDiv.narrow .cell {
	font-size: 14px;
}

.provider .inputBlock-label-summary::before {

}

.provider .inputBlock.optional .inputBlockTitle .inputBlock-label-summary-label {
	/* font-weight: normal; */
}

.provider .inputBlockTitle .inputBlock-label-summary-label::after {
	content: attr(data-optional);
	font-weight: normal;
	font-size: 11px;
	text-transform: uppercase;
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
	color: rgba(0, 0, 0, 0.6);

}

@media screen and (max-width: 370px) {
	.provider .inputBlockTitle .inputBlock-label-summary-label::after {
		display: block;
		padding-left: 0;
	}
}

/*
.provider .inputBlock.required .inputBlockTitle .inputBlock-label-summary-label::after {
	content: "*";
	font-weight: normal;
	font-size: 18px;
	text-transform: uppercase;
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
	color: rgba(255,0,0,0.4);
	transform: translateY(6px);

}
*/
.provider .modalBody.modalPopupSection .spacer {
	height: 20px;
	padding-left: 20px;
	padding-right: 20px;
	line-height: 40px;
	text-transform: uppercase;
	font-size: 12px;
}

.provider .table .labels {
	display: block;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;
	padding-left: 20px;
	position: relative;
}

.provider .table .labels > div {
	float: left;
	max-height: 16px;
	padding: 0;
	display: flex;
	align-items: center;
	margin: 0;
	line-height: 16px;
}

.provider .table .labels > div > span {
	border-radius: 2px;
	/* background: #1bcbac; */
	color: rgba(0, 0, 0, 0.25);

	text-transform: uppercase;
	font-size: 11px;
	display: inline-block;
	padding: 1px;
	padding-left: 2px;
	padding-right: 2px;
	margin: 0;
	max-height: 16px;
	margin-right: 2px;
	border: 1px solid transparent;
	text-align: center;
	position: relative;

}

.provider .table .labels > div > span.active {
	color: rgba(0, 0, 0, 1);
	font-weight: bold;
	/*
	background: #1BCBAC;
	content: "";
	position: absolute;
	bottom: 0;
	left: 2px;
	right: 2px;
	height: 2px;
	*/
}

.provider .table .labels > div.weekend > span {
	color: rgba(120, 0, 0, 0.74);
}

.provider .providerRangeTable.component {
	/*margin-left: -20px;
	margin-right: -20px;*/
	padding: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
}

.provider .providerRangeTable.component .toolbar-el {
	height: 45px;
	background: white;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: none;
}

.provider .providerRangeTable.component .range-container {
	position: relative;
	background: white;
}

.provider .providerRangeTable.component .range {
	background: white;
	position: relative;
	display: flex;
	flex-direction: row;
}

.provider .providerRangeTable.component .range .spacer {
	width: 0;
	display: none;
}

.provider .providerRangeTable.component .range > label {
	height: 50px;
	line-height: 50px;
	background: white;
	padding-left: 20px;
	font-size: 15px;
	flex: 1;
	font-weight: bold;
}

.provider .providerRangeTable.component .range .inputs {
	display: flex;
	flex-direction: row;
	margin-right: 20px;
	align-items: center;
	/*
              input::-webkit-inner-spin-button{
                  border: 1px solid red;
                  height: 5px;
                  width: 5px;
                  display: block;
                  right: 0;
                  top: 0;
  //				position: absolute;
                  -webkit-appearance: none;
                  opacity: 0.5;
              }
              */
}

.provider .providerRangeTable.component .range .inputs .input-row {
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding-left: 20px;
}

.provider .providerRangeTable.component .range .inputs .input-row label {
	order: 0;
	color: rgba(0, 0, 0, 0.4);
	text-transform: uppercase;
	font-size: 13px;
	padding: 0px;
	z-index: 10;

}

.provider .providerRangeTable.component .range .inputs .input-row input:focus,
.provider .providerRangeTable.component .range .inputs .input-row input:focus + label {
	color: #007AFF;
}

.provider .providerRangeTable.component .range .inputs input {
	order: 1;
	outline: none;
	font-size: 15px;
	text-align: center;
	border: none;
	margin-right: 5px;
	-webkit-appearance: none;
	position: relative;
	line-height: 20px !important;
	padding-left: 5px;
	padding-top: 3px;
	display: flex;
	flex-grow: 0;
	align-items: center;
	justify-content: center;
	max-width: 72px;
}

.provider .providerRangeTable.component .range .inputs input::-webkit-date-and-time-value {
	margin: 0;
	padding: 0;
}

.provider .providerRangeTable.component .range-container + .range-container::after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: 20px;
	right: 0;
	height: 1px;
	background: rgba(0, 0, 0, 0.1);
}

.provider .providerRangeTable.component .range-container .timeBar {
	margin-left: 20px;
	margin-right: 20px;
	height: 25px;
	position: relative;
	background: white;
}

.provider .providerRangeTable.component .range-container .timeBar .background {
	position: absolute;
	height: 4px;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 2px;

}

.provider .providerRangeTable.component .range-container .timeBar .bar {
	position: absolute;
	height: 4px;
	background: rgba(0, 0, 0, 0.3);
	transition: all 0.1s ease-in-out;
}

.provider .providerRangeTable.component .range-container .timeBar .ticks {
	left: 0;
	right: 0;
	height: 10px;
	position: absolute;
	bottom: 8px;
	background: transparent;
}

.provider .providerRangeTable.component .range-container .timeBar .ticks .tick {
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0;
	bottom: 0;
	font-size: 10px;
	text-align: center;
	color: rgba(0, 0, 0, 0.4);

}

.provider #intercom-frame {
	/*
	display: none !important;
	overflow: hidden;
	*/
}

.provider .inputBlock-type-amountStepper {
	background: white;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0 !important;
}

.provider .basicInput.basicInput-type-amountStepper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	border: none;

}

.provider .basicInput.basicInput-type-amountStepper label {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 15px;
	font-weight: bold;
	height: 100%;
	padding-left: 20px;
	padding-right: 20px;
}

.provider .basicInput.basicInput-type-amountStepper label .placeholder {
	font-weight: normal;
	font-style: italic;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100px;
	right: 20px;
	text-align: right;
	line-height: 60px;
}

.provider .basicInput.basicInput-type-amountStepper .stepper {
	display: flex;
}

.provider .basicInput.basicInput-type-amountStepper input {
	flex: 0;
	display: block;
	text-align: center;
	width: auto;
	margin-left: 60px;

}

.provider .basicInput.basicInput-type-amountStepper input:not(:focus) {
	border: none;
}

.provider .basicInput.basicInput-type-amountStepper button {
	outline: 0;
	-webkit-tap-highlight-color: transparent;

	height: 30px;
	line-height: 32px;
	padding-left: 10px;
	padding-right: 10px;
	flex: 1;
	width: 45px;
	background: transparent;
	border: 1px solid #1BCBAC;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	color: #1BCBAC;
	font-size: 24px;
}

.provider .basicInput.basicInput-type-amountStepper button:active {
	background: #1BCBAC;
	color: white;

}

.provider .basicInput.basicInput-type-amountStepper button:nth-child(2) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left-color: transparent;
}

.partner-beta-optin-visible {

}
.partner-beta-optin {
	text-align: center;
	color: white;
	height: 50px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	line-height: 50px;
	z-index: 100000;
	background: #67a3ff;
}
.partner-beta-optin a {
	color: white;
	text-decoration: none;
}
.partner-beta-optin a:hover, .partner-beta-optin a:active {
	color: #d7d7d7 !important;
}
.partner-beta-optin .dismiss {
	position: absolute;
	right: 20px;
}

.orderDetails {
	margin-top: 4px;
	margin-bottom: 3px;
	line-height: 1.4em;

	list-style-type: none;
	padding: 0;
	white-space: normal;
}

.orderDetails > li {
}

.orderItemCancelled {
	text-decoration: line-through;
	opacity: 0.5;
}

div.modalDialogDynamicPricingBlock {
	/*margin: 0;*/
	padding: 0 !important; /* sorry... */
}
div.modalDialogDynamicPricingBlock .inputBlockContent {
	height: auto;
	box-shadow: none !important; /* oh lord... */;
	margin: 0 !important; /* argh... */
	padding: 0 !important; /* god damnit... */
}
div.modalDialogDynamicPricingBlock .inputBlockContent .basicInput {
	margin: 10px 0;
}
div.modalDialogDynamicPricingBlock .inputBlockContent .dynamicPricingSmallDescription {
	/*color: #9c9c9c;*/
	opacity: 0.6;
	/*font-weight: normal;*/
	font-size: 13px;
	margin: 0 10px;
}
.dynamicPricingIcon {
	vertical-align: text-bottom;
	margin-right: 8px;
	opacity: 0.6;
}

.priceSummaryDynamicPrice span {
	margin-right: 20px;
	font-size: 13px;
	font-weight: bold;
}

.priceHeaderCell div {
	width: 100%;
	text-align: right;
	padding-right: 20px;
}

span.priceCell {
	border-radius: 14px;
	padding: 7px 10px 3px 10px;
	width: initial;
	position: absolute;
	right: 20px;
	color: white;
	background: #1bcbac;
}

.tableDiv.narrow span.priceCell {
	border-radius: initial;
	padding: initial;
	position: initial;
	right: initial;

	font-size: 13px;
	padding: 2px 5px 0px 5px;
	border-radius: 15px;
}

span.priceCell.left {
	right: initial;
}

span.priceCell.nudgeUp {
position: relative;
	top: -2px;
}

span.priceCell.priceCell-nonsurplus {
	background: #f19b00;
}

span.priceCell.priceCell-nonsurplus-and-surplus {
	/* Hmm! */
	background-image: linear-gradient(150deg, #1bcbac 0%, #1bcbac 50%, #f19b00 50%, #f19b00 100%);
}

span.loweredPrice {
	margin-right: 7px;
	opacity: 0.5;

	background-image: linear-gradient(white, white);
	background-position: 0 41%;
	background-repeat: no-repeat;
	background-size: 100px 1px;
}

img.dynamicPricing {
	vertical-align: top;
	padding-right: 6px;
	height: 15px;
}

.tableDiv.narrow img.dynamicPricing {
	height: 12px;
}

#input_isDynamicPricingEnabled, label[for=input_isDynamicPricingEnabled] {
	float: left !important;
}

.orderEndOffsetInput {
	position: relative;
	/*width: 150px;*/
	width: auto;
	min-width: 130px;
	text-align: center;
	display: inline-block;
	height: 30px;
	padding: 0 30px;
	line-height: 30px;
}

span.nonsurplus, .templateRow-price.nonsurplus {
	color: #f19b00;
}

a.moreInfo {
	position: relative;
	top: -1px;
	font-size: 16px;
	margin-left: 20px;
	text-decoration: none;
	border-bottom: 1px solid;
}

/* Used in offer templates view at least. Same style as in table headings. */
span.itemCode {
	color: #6f6a73;
	text-transform: uppercase;
	font-size: 13px;
	margin-left: 10px;
	margin-left: 5px;
}

.iconButton {
	user-select: none;
}

.iconButton[disabled] {
	opacity: 30%;
	cursor: default;
}

.modalDialog .modalPopupSection .billing-info-subtitle {
  margin: 1.5rem 0;
	font-size: 18px;
	font-weight: bold;
	padding-left: 20px;
}

.modalDialog .modalPopupSection p.billing-info-warning {
  background: #eee;
  font-size: 14px;
  font-style: italic;
  padding: 10px;
  border-radius: 3px;
}

.modalDialog .modalPopupSection .payment-settings-warning {
  background: #eee;
  font-size: 16px;
  padding: 10px;
  border-radius: 3px;
  margin: 1rem;
}

.payment-settings-warning a {
  margin: 0 0.5rem;
  font-weight: 600;
}

.cancelOrder-checkboxPrice {
	font-size: 13px;
	color: #777;
	float: right;
	margin-right: 10px;
}

#warning-banner {
	height: auto;
	width: 100%;
	background-color: #ffd54f;
	padding: 1rem;
	text-align: center;
	font-size: 0.9rem;
}

#warning-banner a {
	margin: 0 0.25rem;
	color: #333;
	font-weight: 600;
}

.kyc-message {
  margin: 1rem 0;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 16px;
  border-radius: 3px;
  margin-bottom: 2rem;
}

.kyc-success {
  background: #8dcd8d;
}

.kyc-warning {
  background: #ffd54f;
}

.adyen-button-wrapper {
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adyen-kyc-button {
  width: 60%;
}
