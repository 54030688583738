
/* Alert */
.alert-background {
	position: fixed;

	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.37);
	z-index: 100000;

	text-align: center;
}

/* These need to to be more specific than responsiveBP's .alert styles. */
/* Also, some rules are there just to override Responsive's. */
.alert-background .alert {
	position: relative;

	display: inline-block !important;

	left: 0;
	right: 0;
	/*width: 600px;*/
	width: auto;
	max-width: 600px;
	min-width: 200px;

	/*top: 50%;*/
	top: 50vh;
	transform: translateY(-50%);
	z-index: 100001;

	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
	margin: 0 auto;
	background: white;
	padding: 30px;

	border-radius: 3px;
	text-align: center;

	border: none;
}

.alert-background .alertWrapper {
	max-width: 90vw;
	margin: 0 auto;
}

.alert-background .alert.alert-wide {
	max-width: 1000px;
	min-width: 50vw;
}

.alert-background .alert > * {
	margin: 0;
}

.alert-background .alert > * + * {
	margin-top: 20px;
}

.alert-background .alert > .messageAlert > * + * {
	margin-top: 20px;
}

.alert-background .alert textarea {
	width: 100%;
	min-height: 20vh;
	font-size: 12px;
	border: 1px solid;
	box-sizing: border-box;
	border-radius: 4px;
}

.alert-background .alert textarea.code {
	font-family: monospace;
}

.alert-background .alert > h1 {
	font-size: 21px;
	margin-bottom: -7px;
}




/* Modal Dialog */
.modal-background {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 20000;
}

.modalDialog p {
	line-height: 1.4em;
}

.modalDialog-container {
	position: absolute;
	top: 0px;
	width: 100%;
	min-height: 100%;

	overflow: visible;
	text-align: center;
}

.modalDialog {
	display: inline-block;
	position: relative;
	max-width: 100%;
	/*font-weight: 400;*/
	margin: 0px auto;
	background-color: #fafafa;
	border: 0px solid white;
	padding: 40px;
	margin-bottom: 50px;
	border-radius: 20px;
	/* box-shadow: 1px 5px 21px 0px rgba(0, 0, 0, 0.4); */
}
.modalDialog-nonpopup {
	top: 0;
	box-shadow: 0 2px 8px rgba(0,0,0,0.2);
	border-radius: 0;
	padding-top: 60px;
	box-shadow: none;
	background: transparent;
}
.modalDialog-container > .modalDialog { /* Only Modal Popup Dialog */
	/* box-shadow: 2px 2px 40px 3px rgba(0, 0, 0, 0.5); */
	z-index: 30000;
}
@media screen and (max-width: 576px) {
	.modalDialog {
		padding: 30px 20px;
		padding-top: 40px;
		width: calc(100%);
		margin: 10px;
		max-width: 100%;
	}
	.modalPopupSection {
		max-width: none !important;
		/* transform: translate3d(0,0,0); */
	}
	.provider .inputBlock:not(.inputBlock-fixed) {
		width: 100% !important;
		display: block;
		margin-left: 0 !important;
		margin-right: 0;
	}
}
.modalCloseButton {
	cursor: pointer;
	padding: 23px;
	position: absolute;
	right: -5px;
	top: -8px;
	border-radius: 30%;
	opacity: 0.5;
	z-index: 10000;
}
.modalCloseButton[disabled] {
	opacity: 0.3;
}
.modalCloseButton:hover:not([disabled]) {
	opacity:1;
}
.modalCloseButton img {
	width: 18px;
	height: 18px;
}

.modal-title {
	text-align: center;
	margin-bottom: 30px;
}
.modal-title * {
	margin: 0;
}

.modalBody {
	text-align: left;
	-webkit-overflow-scrolling: touch;
}

.modalBody > button {
	margin-bottom: 10px;
}

.modalFooter {
	text-align: center;
	padding-top: 15px;
}

.modal-horizontalLine {
	border-bottom: 2px solid #f0f0f0;
	margin-bottom: 10px;
}

.modal-button {
	min-width: 30%;
}

.modal-button:first-of-type {
	margin-right: 0.9%;
}
.modal-button:last-of-type {
	margin-left: 0.9%;
}

.modalDialog-documentPreview {
	display: block;
	width: 100%;
	height: calc(100vh - 40px);
	top: 20px !important;
}

.modalDialog-documentPreview > .modalBody > iframe {
	width: 100%;
	height: calc(100vh - 180px);
	border-width: 1px;
}

.modalBody > .locationHelper {
	font-size: 14px;
	margin-bottom: 8px;
}
.modalDialog {
	/* width: 100%; */
	margin: 0;
}

/* Modal Dialog Component CSS */

.modalDialog .modal-title {
	margin: 0;
	text-align: left;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	text-transform: uppercase;
}
.modalDialog.modalDialog-nonpopup .modal-title {
	border: none;
}

.modalDialog .modal-title h2 {
	margin: 12px 0;
	font-size: 18px;
	line-height: 1.4em;
}

.modalDialog .modalPopupSection p {
	margin: 20px;
}

.modalBody.disable-image-input .input-image-url {
	/* To test the hidden state */
	/*opacity: 0.25;*/
	display: none;
}

.modalBody > .inputBlockTitle {
	padding-left: 20px;
}

.alert.lightPopup {
	text-align: left;
}
.alert.lightPopup .lightPopupButtons {
	text-align: right;
}

.lightPopupButton {
	color: #1bcbac;
	font-weight: bold;
	margin-left: 40px;
	margin-top: 8px;
}
