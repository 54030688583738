span.reviewRating[reviewrating='good'] {
    color: #00cfab;
}

span.reviewRating[reviewrating='okay'] {
    /* color: #90900f; */
}

span.reviewRating[reviewrating='bad'] {
    color: #e05e5e;
}
